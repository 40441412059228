/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('a[href*="#"]:not([href="#"])').click(function() {
          if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
              $('html, body').animate({
                scrollTop: target.offset().top - 78
              }, 1000);
              return false;
            }
          }
        });
        if ( $( ".grid" ).length ) {
          setTimeout(function() {
            var msnry = new Masonry( '.grid', {
              itemSelector: '.grid-item',
              columnWidth: '.grid-sizer',
              gutter: '.gutter-sizer',
              percentPosition: true
            });
          }, 200);
        }
        $('textarea').textareaAutoSize();
        // WOW
        new WOW().init();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.caroussel').slick({
          infinite: true,
          slidesToShow: 1,
          dots: false,
          arrows: false,
          autoplay: true,
          fade: true,
          autoplaySpeed: 3000,
        });

        $('.gallerie-caroussel').slick({
          slidesToShow: 6,
          slidesToScroll: 1,
          autoplay: true,
          infinite: true,
          autoplaySpeed: 3000,
        });

        $('#single-scroll').click(function(e) {
        	e.preventDefault();
        	$('body').animate({
        		scrollTop:jQuery(window).height() - 80
        	});
        });

        // Add class for sticky header
        var introTitlePositionTop = $('#intro-logo').offset().top;
        $(window).scroll(function (event) {
          var scroll = $(window).scrollTop();

          if (scroll > introTitlePositionTop) {
            $('#header-sticky').addClass('open');
            $('#main-header').addClass('close');
          }else{
            $('#header-sticky').removeClass('open');
            $('#main-header').removeClass('close');
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
